<template>        
    <v-menu v-if="dropdown" offset-y bottom :nudge-bottom="nudgeBottom" :closeOnContentClick="closeOnContentClick">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" :class="cls" class="px-10" 
                :loading="loading" 
                :block="block"  
                outlined
                :large="large"        
                :color="compColor" 
                @click="onClick">
                
                <span v-if="compIcon && !dropRight" class="icon"><Icon :small="compIconSmall" class="btn-icon mr-2" :type="compIcon"></Icon></span>
                <span class="text"><slot>{{compText}}</slot></span>
                <span v-if="compIcon  && dropRight" class="icon"><Icon :small="compIconSmall" class="btn-icon ml-2" :type="compIcon"></Icon></span>
            </v-btn>
        </template>
        <slot name="dropmenu">
            <v-list dense>
                <slot name="dropdown"></slot>
            </v-list>
        </slot>
    </v-menu>
        <span v-else-if="linkButton" class="link-button text-primary" :class="cls" @click="onClick">
            <span class="mr-1" v-if="compIcon">
                <Icon color="primary" :small="compIconSmall" class="btn-icon" :type="compIcon"></Icon>
            </span>
            <slot>{{compText}}</slot>
        </span>
    <v-btn v-else-if="iconButton" :class="cls" class="ipx-10" 
        icon
        :color="compColor" 
        @click="onClick">        
        <span><Icon :small="compIconSmall" class="btn-icon mr-2" :type="compIcon"></Icon></span>
    </v-btn>
    <v-btn v-else-if="iconRoundedButton" :class="cls" class="px-2" 
        :loading="loading" 
        :block="block"  
        outlined
        :large="large"        
        :color="compColor" 
        @click="onClick">        
        <span v-if="compIcon" class="icon"><Icon :small="compIconSmall" class="btn-icon" :type="compIcon"></Icon></span>        
    </v-btn>
    <v-btn v-else :class="cls" class="px-10" 
        :loading="loading" 
        :block="block"  
        outlined
        :large="large"        
        :color="compColor" 
        @click="onClick">
        
        <span v-if="compIcon" class="icon"><Icon :small="compIconSmall" class="btn-icon mr-2" :type="compIcon"></Icon></span>
        <span class="text"><slot>{{compText}}</slot></span>
    </v-btn>
</template>

<script>
import navigation from '@app/navigation'
import Icon from '@shared/ui/controls/icons/Icon'

//
// Action buttons are buttons where the action is defined in the navigation component.
// The navigation component maintains a list of actions and can execute those. 
// Also, the navigation component is authorization aware and we use it to determine whether 
// an action can be executed by this user. The advantage is that all metadata is in navigation.js and 
// not scattered throughout the client application. 
// Note that of course, on the server, also authorization is checked. 
// 
// Note that when no tekst is specified, the tekst is retrieved from the specified action - if any - unless the no-text attribute is used.
//
// Note that authorization and action are inored when action is not specified.
// This is usable for e.g. utility buttons, and also for dropdown buttons.
//
// As per conformation to the v2 design, we define the following types of buttons: 
// - default  - when no type attributes are defined, this is the default
// - main     - activated by attribute 'main'. 
// - main primary - activated by specifying the attributes main and primary
// - sub      - activated by attribute 'sub'.
// - dropdown - activated by attribute 'dropdown'.
//
// Usage: 
//
//   import ActionButton from '@shared/ui/controls/buttons/ActionButton'
//   import ActionButton from '@controls/buttons/ActionButton'
//  
//   // A main primary button with a send icon
//   <ActionButton main primary icon="send" action='invoice.send'></ActionButton>
//
//   // A main button with a pdf icon. We check on invoice.access rights, but execute our own action to show the PDF.
//   <ActionButton main icon="pdf" action='invoice.access' no-execute @click="onPdf">Voorbeeld</ActionButton>
//
//   // A default button with an icon, where we show the payment history
//   // Note that we send the invoice model as payload so that the coupled action knows which invoice we are talking about. 
//   <ActionButton icon="history" action='invoice.history' payload="model">Geschiedenis</ActionButton>
//
//   // A sub button with an icon. This example adds a line to an invoice and as such, the modify action is checked.
//   // Note that we send the invoice model as payload so that the coupled action knows which invoice we are talking about. 
//   <ActionButton sub icon="add" action='invoice.modify' no-execute @click="onAddLine">Nieuwe regel</ActionButton>
//
//   // A sub button which acts as a dropdown button. 
//   // Since we do not want to restrict the dropdown itself, no action is specified.
//   import MenuItem from "@/components/ui/controls/menu/ActionMenuItem.vue";
//   <ActionButton sub dropdown>
//      <template v-slot:dropdown>
//            <ActionMenuItem action="invoice.modify" no-execute  @click="onAddEmptyTextLine">Lege regel</ActionMenuItem> 
//            <ActionMenuItem action="invoice.modify" no-execute  @click="onAddStandardTextLine">Standaard tekst</ActionMenuItem> 
//      </template>
//      Tekst
//   </ActionButton>
// 
export default {
    name: 'ActionButton',
    components: {Icon},
    props: {
        action: {
            type: [String],
            default: null
        },     
        main: {
            type: [Boolean],
            default: false
        },
        primary: {
            type: [Boolean],
            default: false
        },
        sub: {
            type: [Boolean],
            default: false
        },
        dropdown: {
            type: [Boolean],
            default: false
        },
        dropRight: {
            type: [Boolean],
            default: false
        },
        nudgeBottom: {
            type: [String, Number],
        },
        closeOnContentClick: {
            type: [Boolean],
            default: true
        },        
        noText: {
            type: [Boolean],
            default: false
        },
        disabled: {
            type: [Boolean],
            default: false
        },
        // color and icon are optional. 
        // For 'standard' types, colors and icons are predefined and can be overruled by specifying color and / or icon property.
        color: {
            type: [String],
            default: ""
        },
        noIcon: {
            type: [Boolean],
            default: false
        },
        icon: {
            type: [String]                
        },
        // is the button an icon button
        iconButton: {
            type: [Boolean],
            default: false
        },
        linkButton: {
            type: [Boolean],
            default: false
        },
        // is the button a rounded icon button
        iconRoundedButton: {
            type: [Boolean],
            default: false
        },
        // no-border is applicable only in combination with iconButton. 
        noBorder: {
            type: [Boolean],
            default: false
        },
        block: {
            type: [Boolean],
            default: false
        },
        large: {
            type: [Boolean],
            default: false
        },
        loading: {
            type: [Boolean],
            default: false
        },
        // Margin left
        ml: {
            type: [Boolean],
            default: false
        },
        noExecute: {
            type: [Boolean],
            default: false
        },
        payload: {
            type: [Object, Array, String, Number],
        },
    },
    data () {
        return {             
            actionItem: {},
        }
    },

    methods: {
        onClick() {
            if (this.disabled) {
                return; // Do nothing.
            }
            if (!this.action || this.noExecute) {
                this.$emit('click');
                return;
            }            
            if (navigation.executeAction(this.action, this.payload)) {
                this.$emit('click')
            }
        },
    },    

    computed: {
        compText:function() {
            if (this.noText) {
                return '';
            }
            return this.actionItem.text || '';
        },
        compColor: function() {
            return this.color;
        },
        cls: function() {
            let disabled = this.compDisabled ? "disabled":"";
            let main = this.main?"main":"";
            let primary = this.primary?"primary":"";
            let dd = this.dropdown?"dropdown":"";
            let sub = this.sub?"sub":"";
            let ml = this.ml?"ml-2":"";
            return `action-button ${main} ${primary} ${dd} ${sub} ${disabled} ${ml}`;
        },
        compDisabled: function() {
            if (this.disabled) {
                return true;
            }
            if (undefined == this.actionItem.isAuthorized) {
                return false;
            }
            return !this.actionItem.isAuthorized;
        },
        compIconSmall: function() {
            return this.compIcon != 'dropdown';
        },
        compIcon: function() {
            if (this.noIcon) {
                return null;
            }
            let ico = this.icon;
            if (this.dropdown) {
                ico = "dropdown";
            }
            if (!ico && this.actionItem) {
                ico = this.actionItem.type;
            }

            return ico;
        },
    },

    mounted() {
        if (this.action) {
            this.actionItem = navigation.getAction(this.action) || {};
        }
        else {
            // Normal button, always authorized.
            this.actionItem = {isAuthorized: true}
        }

    },

}

</script>
